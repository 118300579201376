import {Dispatch, SetStateAction} from 'react'
import {Topic} from "../../../app/modules/topic/core/_models";
// import {presentPageSize} from "../../../app/modules/videocontent/components/masters-list/components/pagination/MastersListPagination"

export type ID = undefined | null | number

export type PaginationState = {
  page: number
  items_per_page: 10 | 20 | 50 | 100 | 250 //find pageSize in /src/app/modules/videocontent/components/masters-list/components/pagination/MastersListPagination.tsx
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
  total?: number
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

export type ClientState = {
  clients?: string
}

export type StatusState = {
  status?: string
}

export type StrategyState = {
  strategy?: string
}

export type SourceState = {
  source?: string
}

export type FavouriteState = {
  favourite?: boolean
}
export type StartDateState = {
  startDate?: number
}
export type EndDateState = {
  endDate?: number
}

export type ReportCurrentTrend<T> = {
  currentWeekVol?: number,
  pastWeekVol?: number,
  privousWeekVol?: number,
  priviousPastWeekVol?: number,
  currentWeekChange?: number,
  pastWeekChange?: number,
  privousWeekChange?: number,
}

export type Response<T> = {
  recordsTotal?: number
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type ShareOfVoiceResponse<T> = {
  dateRange?: string[]
  data?: T
}

export type DashBoardResponse<T> = {
  activeTopicNum?: number,
  activeKeywordNum?: number,
  messageLastDays?: number,
  messageLastThirtyDays?: number,
  commentLastDays?: number,
  commentLastThirtyDays?: number,
  conversationLastDays?: number,
  conversationLastThirtyDays?: number,
  openedTopicTicket?: number,
  progressTopicTicket?: number,
  activeVideoTopicNum?: number,
  activeVideoTopicKeywordNum?: number,
  currentDaysVideo?: number,
  currentThirtyDaysVideo?: number,
  openedVideoTopicTicket?: number,
  progressVideoTopicTicket?: number,
  activeEmailDigestNum?: number,
  emailSentDay?: number,
  emailSentMonth?: number,
  activeWikiDefenderNum?: number,
  changeDetectedDay?: number,
  changeDetectedMonth?: number,
  topics?: Topic[],
}

export type QueryState =
  PaginationState
  & SortState
  & FilterState
  & SearchState
  & ClientState
  & StatusState
  & SourceState
  & StrategyState
  & FavouriteState
  & StartDateState
  & EndDateState


export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  items_per_page: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
  faceDetectionUpdateId?:ID
  setFaceDetectionUpdateId?:Dispatch<SetStateAction<ID>>
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
  setFaceDetectionUpdateId:()=>{},
}
